import { css } from "glamor"
import React from "react"
import { FACEBOOK_BLACK } from "../../assets/colors/Colors"
import XY from "../composables/XY"
import Y from "../composables/Y"
import { SubtitleIIIRsp } from "./Auth"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';






export const ProgressStepBar = ( props : { 
    step : number, 
    value : string, 
    steps ?: number, 
    disableBack ?: boolean, 
    goBack ?: () => void 
    c?: string,
    styles ?: {
        font?: Record<string,any>,
        container?: Record<string,any>,
    }

}) => {
    const h : number = 40
    const steps : number = props.steps ? props.steps : 5
    const stepSize : number = 100 / steps
    const stepWidth : number = (stepSize * props.step)
    
    const margin : number = 50
    return <div style={{ height : h }}><div {...css({ 
        height : h, 
        width : `calc(100% - ${margin}px)`,
        marginLeft : margin/2,
        backgroundColor : '',
        ...props.styles?.container
    })}>
        
        <div style={{ width : '100%', height : 'calc(100% - 2px)', backgroundColor : ''}}>
            <Y>
                <SubtitleIIIRsp c={ props.c ? props.c : FACEBOOK_BLACK} style={props.styles?.font}>
                    {(`${props.step}/${steps} ${props.value}`).toUpperCase()}
                </SubtitleIIIRsp>
            </Y>
        </div>
        <div style={{ width : '100%', height : 2, backgroundColor : '', borderRadius : 5, overflow : 'hidden' }}>
            { (props.step > 1 && !props.disableBack ) && <ArrowButton style={{ position : 'absolute', marginLeft : 4, marginTop : -40 }} onClick={props.goBack}/>}
            <div style={{ width : '100%', height : '100%', }}></div>
            <div style={{ width : `${stepWidth}%`, height : '100%', marginTop : -2, backgroundColor :  props.c ? props.c : FACEBOOK_BLACK }}></div>
        </div>
    </div></div>
  
}

const ArrowButton = ( props : { style ?: Record<string,any>, onClick ?: () => void }) => {
    return (
        <div {...css({ 
                width : 34, 
                height : 34, 
                borderRadius : '100%', 
                cursor : 'pointer',backgroundColor : '', 
                [':hover'] : {  backgroundColor : 'rgba(0,0,0,0.1)'}, 
                [':active'] : {  backgroundColor : 'rgba(0,0,0,0.05)'},
                ...props.style,
            })
        }
        onClick={props.onClick}
        >
            <XY>
                <KeyboardBackspaceIcon/>
            </XY>
        </div>
    )

}